import { useOrderDetails } from 'modules/orders/hooks'

import { Box } from 'ui'
import {
  OrderDetailsTitle,
  OrderDetailsTable,
  OrderDetailsInfo,
} from 'modules/orders/pages/OrderDetails/components'

import type { FC } from 'react'

const OrderDetails: FC = () => {
  useOrderDetails()

  return (
    <Box>
      <Box pt='24px' px='24px' mb='24px'>
        <OrderDetailsTitle />
        <OrderDetailsInfo />
      </Box>
      <OrderDetailsTable />
    </Box>
  )
}

export { OrderDetails }
